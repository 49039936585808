<template>
  <manger-page-header
    v-model:value="btnIndex"
    :btnList="btnList"
    @changeIndex="changeIndex"
  >
    <a-select
        class="myselect box-shadow"
        v-model:value="model"
        style="width: 120px"
        ref="select"
        @change="changeModel"
      >
        <!-- <a-select-option :value="0">普通票</a-select-option> -->
        <a-select-option :value="1">模板票</a-select-option>
        <a-radio :value="2">大小项卡</a-radio>
      </a-select>
    <a-input
      placeholder="搜索操作票id、编号、名称"
      v-model:value="search"
      @keyup.enter="fetchSearch"
    ></a-input>
  </manger-page-header>
  <div v-if="btnIndex == 0">
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      class="table"
      rowKey="id"
      @change="changePage"
    >
      <template #action1="{ record }">
        <a-button type="link" size="small" @click="goEditTicket(record.id)"
          >编辑</a-button
        >
        <a-button
          type="link"
          size="small"
          class="col-red"
          @click="fetchDeleteTicket(record.id)"
          >删除</a-button
        >
      </template>
    </a-table>
  </div>
  <!-- 新建操作票 start -->
  <create-item v-if="btnIndex == 1" @changeIndex="changeIndex"></create-item>
  <!-- 新建操作票 end -->
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import mangerPageHeader from '@/components/btn-and-search.vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import { getTicketList, deleteTicket } from '@/api/index'
import { showConfirm, setPage } from '@/hooks/common-hooks'
import createItem from '@/views/ticketManger/components/createItem'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
// import '@/mock/index'
const btnList = [
  {
    id: 1,
    btnName: '操作票列表'
  },
  {
    id: 2,
    btnName: '新建操作票'
  }
]
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 60
  },
  {
    title: '编号',
    dataIndex: 'ticket_num',
    key: 'ticket_num',
    width: 120
  },
  {
    title: '操作名称',
    dataIndex: 'ticket',
    key: 'ticket'
  },
  {
    title: '操作任务',
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
const pagination = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  name: 'TicketManger',
  components: {
    mangerPageHeader,
    createItem
  },
  setup() {
    const router = useRouter()
    const tableData = reactive({
      columns,
      data: [],
      search: '',
      pagination,
      model: 1
    })
    // 获取列表
    const fetchGetTicketList = isDelete => {
      getTicketList({
        search: tableData.search,
        model: tableData.model,
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          fetchGetTicketList()
        }
      })
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(fetchGetTicketList)
    // 搜索列表
    const fetchSearch = () => {
      tableData.pagination.current = 1
      fetchGetTicketList()
    }
    // 翻页
    const changePage = pagination => {
      tableData.pagination.current = pagination.current
      fetchGetTicketList()
    }
    // 删除
    const fetchDeleteTicket = id => {
      showConfirm({
        content: '此操作不可逆，确定要删除该操作票吗?',
        callBack: () => {
          deleteTicket({ id }).then(() => {
            message.success('操作成功')
            fetchGetTicketList(true)
          })
        }
      })
    }
    // 编辑
    const goEditTicket = id => {
      router.push({ name: 'EditTicket', params: { id } })
    }
    const changeModel = (value) => {
      tableData.model = value
      console.log(tableData.model)
      fetchGetTicketList()
    }

    onMounted(() => {
      fetchGetTicketList()
    })
    return {
      btnList,
      btnIndex,
      changeIndex,
      fetchSearch,
      changePage,
      ...toRefs(tableData),
      fetchDeleteTicket,
      goEditTicket,
      changeModel
    }
  }
})
</script>

<style>
</style>
